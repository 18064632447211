.pin-card {
  margin-bottom: 0;
  position: relative;
  break-inside: avoid;
  height: 100%;
  overflow: hidden;
  transition: transform 0.2s ease;
}

.pin-card:hover {
  transform: scale(1.05);
  z-index: 1;
}

.pin-card img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.like-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.like-button:hover {
  transform: scale(1.15);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.like-button .heart-icon {
  font-size: 18px;
  line-height: 1;
  color: #e60023;
  transition: transform 0.2s ease;
  transform: scale(1.4);
}

.like-button.liked {
  background-color: #e60023;
  transform: scale(1.15);
}

.like-button.liked .heart-icon {
  color: white;
}

.like-button span {
  font-size: 14px;
  font-weight: 600;
  color: #211922;
  display: none;
} 