.app {
  text-align: center;
}

.toggle-button {
  padding: 10px 20px;
  margin: 20px;
  background-color: #e60023;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease;
}

.toggle-button:hover {
  background-color: #ad081b;
}

.loading {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  z-index: 1000;
}

.layout-divider {
  width: 2px;
  background: linear-gradient(
    to bottom,
    transparent,
    #e60023 20%,
    #e60023 80%,
    transparent
  );
  margin: 0 16px;
  height: 100vh;
  position: sticky;
  top: 0;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.layout-divider:hover {
  opacity: 1;
}