.split-layout {
  display: flex;
  width: 100%;
  gap: 0;
  align-items: flex-start;
}

.masonry-layout {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 12px;
  padding: 12px;
  grid-auto-rows: 30vh;
}

.masonry-layout.full-width {
  max-width: 1200px;
  margin: 0 auto;
}

.layout-divider {
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 0 8px;
}

.pin-wrapper {
  break-inside: avoid;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.grid-row {
  display: contents;
} 